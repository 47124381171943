import { css } from '@emotion/react';

import { messageStyles } from './InstructorSignIn';
import PageWrapper from './PageWrapper';

const PasswordResetMessage: React.FC<{ message: string }> = ({ message }) => (
	<PageWrapper pageHeading="" title="Password Reset | Soomo">
		<div css={[messageStyles, styles]} dangerouslySetInnerHTML={{ __html: message }} />
	</PageWrapper>
);

const styles = css`
	p,
	ul,
	ol {
		margin: 1em 0;
	}

	ul,
	ol {
		text-align: left;
	}
`;

export default PasswordResetMessage;
