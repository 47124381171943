import { useCallback, useState } from 'react';
import type { FormEventHandler } from 'react';

import axios from 'axios';

import { logError } from '~/utils/rollbar';

import { endpoints } from './constants';

export default function useResetPasswordForm() {
	const [isSubmitted, setSubmitted] = useState(false);
	const [message, setMessage] = useState<string | null>(null);
	const [error, setError] = useState(null);

	const handleFormSubmit: FormEventHandler<HTMLFormElement> = useCallback(async (e) => {
		const payload = {
			email: (e.target as HTMLFormElement).email.value
		};
		try {
			e.preventDefault();
			const response = await axios.post(endpoints.passwordResets, payload, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json'
				}
			});
			if (response.status === 200 && response.data.lms_signin_instructions) {
				setMessage(response.data.lms_signin_instructions);
			}
			setSubmitted(true);
		} catch (e) {
			setError(e);
			logError(e);
		}
	}, []);

	return { error, message, isSubmitted, handleFormSubmit };
}
