import { useCallback, useState } from 'react';
import type { ChangeEventHandler } from 'react';
import { Link } from 'react-router-dom';

import Button from './Button';
import { routerPath } from './constants';
import { formStyles, linkStyles, messageStyles } from './InstructorSignIn';
import PageWrapper from './PageWrapper';
import PasswordResetMessage from './PasswordResetMessage';
import ResetEmailSent from './ResetEmailSent';
import useResetPasswordForm from './useResetPasswordForm';
import WGUSupportPage from './WGUSupportPage';

const StudentForgotPassword: React.VFC = () => {
	const [email, setEmail] = useState('');
	const [isWGU, setIsWGU] = useState(false);

	const { handleFormSubmit, isSubmitted, message } = useResetPasswordForm();

	const onFormSumbit = (e) => {
		if (email.endsWith('@wgu.edu')) {
			setIsWGU(true);
			return;
		}

		handleFormSubmit(e);
	};

	const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
		setEmail(e.target.value);
	}, []);

	if (isWGU) {
		return <WGUSupportPage />;
	}
	if (isSubmitted) {
		if (message) {
			return <PasswordResetMessage message={message} />;
		} else {
			return <ResetEmailSent email={email} backLink={routerPath.studentSignIn} />;
		}
	}
	return (
		<PageWrapper pageHeading="Forgot your password" title="Forgot Your Password | Soomo">
			<div css={messageStyles}>We’ll send you an email with instructions.</div>
			<form onSubmit={onFormSumbit} css={formStyles}>
				<label htmlFor="email">Email address</label>

				<input
					id="email"
					name="email"
					type="email"
					required
					placeholder="Email Address"
					value={email}
					onChange={handleChange}
					autoComplete="username"
				/>

				<div className="form-footer">
					<div className="links-block">
						<Link to={routerPath.studentSignIn} css={linkStyles}>
							Or go back to sign-in
						</Link>
					</div>

					<Button type="submit">Send</Button>
				</div>
			</form>
		</PageWrapper>
	);
};

export default StudentForgotPassword;
